import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import './style.scss';

import Modal from '../../UI/Modal';
import ReferrerCard from '../../UI/ReferrerCard';
import Heading from '../../UI/Texts/Heading';

import Close from '../../../images/CloseIcon.png';
import Loader from '../../UI/Loader';

function RefereeModal({
  referrer,
  toggle,
  showModal,
  refereeList,
  next,
  hasMore,
  loading,
}) {
  useEffect(() => {
    if (showModal) {
      next();
    }
  }, [showModal]);

  return (
    <Modal showModal={showModal} toggle={toggle} customClass="RefereeModal">
      <div className="RefereeModal__Top">
        <Heading heading={`${referrer?.name} (${referrer?.count})`} />
        <div className="RefereeModal__Top--Close" onClick={toggle}>
          <img src={Close} alt="" />
        </div>
      </div>

      <div className="RefereeModal__Body">
        {loading ? (
          <Loader
            customProps={{ style: { minHeight: '70vh', marginTop: '16px' } }}
          />
        ) : (
          <InfiniteScroll
            dataLength={refereeList?.length}
            next={next}
            hasMore={hasMore}
            height={'60vh'}
          >
            {refereeList?.map((item, i) => (
              <ReferrerCard
                key={i}
                name={item?.name}
                image={item?.iconUrl}
                subtext={item?.typetext}
                textColor={item?.typetextColor}
                // customProps={{ style: { margin: '16px 0 0' } }}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </Modal>
  );
}

export default RefereeModal;

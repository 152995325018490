import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './style.scss';

import Navbar from '../../UI/Navbar';
import Heading from '../../UI/Texts/Heading';
import Footer from '../../UI/Footer';
import SubHeading from '../../UI/Texts/SubHeading';
import InputField from '../../UI/InputField';
import Loader from '../../UI/Loader';

import { GET, PUT } from '../../../utilities/constants';
import { apicall } from '../../../utilities/apiCall';
import Toast from '../../UI/Toast';

function Edit({ history }) {
  const localHistory = useHistory();
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [referrerPercent, setReferrerPercent] = useState();
  const [refereePercent, setRefereePercent] = useState();
  const [referrerMaxAmount, setReferrerMaxAmount] = useState();
  const [refereeMaxAmount, setRefereeMaxAmount] = useState();
  const [minCousePrice, setMinCousePrice] = useState();
  const [courseCount, setCourseCount] = useState();

  const [toastMessage, setToastMessage] = useState();
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (history?.location?.state) {
      const data = history?.location?.state;
      setReferrerPercent(data?.referrer?.referrerPercent);
      setReferrerMaxAmount(data?.referrer?.referrerMaxAmount);
      setRefereePercent(data?.referee?.refereePercent);
      setRefereeMaxAmount(data?.referee?.refereeMaxAmount);
      setMinCousePrice(data?.minCousePrice);
      setCourseCount(data?.courseCount);

      setLoading(false);
    }
  }, [history?.location?.state]);

  const getCourseCount = () => {
    let action = {
      type: GET,
      url: `v2/course/price/count?minprice=${minCousePrice}`,
      storeData: (e) => setCourseCount(e?.count),
      catchError: (e) => {
        setToastMessage(e);
        setCourseCount(0);
      },
    };

    apicall(action);
  };

  const editRewards = () => {
    setApiLoading(true);
    let body = {
      id: localStorage.getItem('ReferralID'),
      referee: {
        refereePercent: parseInt(refereePercent),
        refereeMaxAmount: parseInt(refereeMaxAmount),
      },
      referrer: {
        referrerPercent: parseInt(referrerPercent),
        referrerMaxAmount: parseInt(referrerMaxAmount),
      },
      minCoursePrice: parseInt(minCousePrice),
    };

    let action = {
      type: PUT,
      url: 'v2/course/referral/orgConfig',
      storeData: () => {
        setApiLoading(false);
        localHistory.push('/tutor/rewards', { toastMessage: 'Reward Saved' });
      },
      catchError: (e) => {
        setToastMessage(e);
        setApiLoading(false);
      },
      body,
    };

    apicall(action);
  };

  useEffect(() => {
    if (toastMessage) {
      setShowToast(true);
    }
  }, [toastMessage]);

  useEffect(() => {
    window.onMobileUpdate = () => {
      localHistory.push('/tutor/rewards');
    };
  }, []);

  return (
    <div className="EditPage">
      <Navbar
        heading="Edit Rewards"
        onClick={() => localHistory.push('/tutor/rewards')}
      >
        <Heading
          heading="FAQs"
          customProps={{
            onClick: () => localHistory.push('/tutor/faqs'),
            style: { color: '#ffffff' },
          }}
        />
      </Navbar>

      {loading ? (
        <Loader />
      ) : (
        <div className="EditPage__Body">
          <div className="EditPage__Body__Section">
            <div className="EditPage__Body__Section__Top">
              <Heading heading="Your Students" />
              <SubHeading subHeading="When their friends buys your course" />
            </div>
            <div className="EditPage__Body__Section__Inputs">
              <InputField
                label="Percent(%)"
                type="number"
                value={referrerPercent}
                onChange={(e) => {
                  if (e.target?.value >= 0) {
                    setReferrerPercent(e.target?.value);
                  } else {
                    setReferrerPercent(Math.abs(e?.target?.value));
                  }
                }}
              />
              <InputField
                label="Max Paytm Cash(₹)"
                type="number"
                value={referrerMaxAmount}
                onChange={(e) => {
                  if (e.target?.value >= 0) {
                    setReferrerMaxAmount(e.target?.value);
                  } else {
                    setReferrerMaxAmount(Math.abs(e?.target?.value));
                  }
                }}
              />
            </div>
          </div>
          <div className="EditPage__Body__Section">
            <div className="EditPage__Body__Section__Top">
              <Heading heading="New Students" />
              <SubHeading subHeading="Reward their friends for first purchase:" />
            </div>
            <div className="EditPage__Body__Section__Inputs">
              <InputField
                label="Percent(%)"
                type="number"
                value={refereePercent}
                onChange={(e) => {
                  if (e.target?.value >= 0) {
                    setRefereePercent(e.target?.value);
                  } else {
                    setRefereePercent(Math.abs(e?.target?.value));
                  }
                }}
              />
              <InputField
                label="Max Discount(₹)"
                type="number"
                value={refereeMaxAmount}
                onChange={(e) => {
                  if (e.target?.value >= 0) {
                    setRefereeMaxAmount(e.target?.value);
                  } else {
                    setRefereeMaxAmount(Math.abs(e?.target?.value));
                  }
                }}
              />
            </div>
            <div className="EditPage__Body__Section__Threshold">
              <InputField
                label="Can be applied on a minimum course price(₹)"
                type="number"
                value={minCousePrice}
                onChange={(e) => {
                  if (e.target?.value >= 0) {
                    setMinCousePrice(e?.target?.value);
                  } else {
                    setMinCousePrice(Math.abs(e?.target?.value));
                  }
                }}
                customProps={{ onBlur: getCourseCount }}
              />
              <SubHeading
                customProps={{
                  style: { color: courseCount ? '#666666' : '#FF4058' },
                }}
                subHeading={`Eligible courses: ${courseCount}`}
              />
            </div>
          </div>
        </div>
      )}

      <Footer
        label={apiLoading ? 'Please Wait...' : 'Save Reward'}
        onClick={() => editRewards()}
        type={1}
      />

      {showToast ? (
        <Toast
          text={toastMessage}
          toggle={() => {
            setShowToast(false);
            setToastMessage();
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Edit;

import React from 'react';
import './style.scss';

function Popup({ customClass, customProps, showPopup, children, handleClose }) {
  return (
    <div
      className={
        showPopup
          ? 'PopupWrapper PopupWrapper__Show'
          : 'PopupWrapper PopupWrapper__Hide'
      }
    >
      <div
        onClick={handleClose}
        className={
          showPopup ? 'Backdrop Backdrop__Show' : 'Backdrop Backdrop__Hide'
        }
      />
      <div
        className={
          showPopup
            ? `Popup Popup__Show ${customClass ? customClass : ''}`
            : `Popup Popup__Hide ${customClass ? customClass : ''}`
        }
        {...customProps}
      >
        {children}
      </div>
    </div>
  );
}

export default Popup;

import React from 'react';
import './style.scss';

import Heading from '../Texts/Heading';
import SubHeading from '../Texts/SubHeading';

function RewardCard({ heading, subHeading, icon, reward, detail }) {
  return (
    <div className="RewardCard">
      <div className="RewardCard__Label">
        <Heading heading={heading} />
        <SubHeading subHeading={subHeading} />
      </div>
      <div className="RewardCard__Reward">
        <div className="RewardCard__Reward__Icon">
          <img src={icon} alt="" />
        </div>
        <div className="RewardCard__Reward__Details">
          <Heading
            heading={reward}
            customProps={{ style: { fontSize: '14px' } }}
          />
          <SubHeading
            subHeading={detail}
            customProps={{ style: { fontSize: '12px' } }}
          />
        </div>
      </div>
    </div>
  );
}

export default RewardCard;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './style.scss';

import Heading from '../../UI/Texts/Heading';
import RewardCard from '../../UI/RewardCard';

import Discount from '../../../images/Discount.png';
import Paytm from '../../../images/Paytm.png';
import EditIcon from '../../../images/EditIcon.png';
import Button from '../../UI/Button';
import IntroModal from '../IntroModal';

function RewardSection({ data }) {
  const history = useHistory();
  const [showIntroModal, setShowIntroModal] = useState(false);
  const rewards = [
    {
      heading: 'Your Students',
      subHeading: 'When their friend buys your course',
      reward: `${data?.referrer?.referrerPercent}% upto ₹${data?.referrer?.referrerMaxAmount}`,
      detail: 'Paytm Cash',
      icon: Paytm,
    },
    {
      heading: 'New Student',
      subHeading: 'Reward their friends for first purchase:',
      reward: `${data?.referee?.refereePercent}% upto ₹${data?.referee?.refereeMaxAmount}`,
      detail: 'Coupon DisCount',
      icon: Discount,
    },
  ];

  return (
    <div
      className="RewardSection"
      style={{ borderBottom: data?.showFAQ ? '8px solid #e5e5e5' : 'none' }}
    >
      <div className="RewardSection__Top">
        <Heading heading="Set Rewards" />
        <Heading
          heading="How it works?"
          customProps={{
            style: { color: '#009ae0' },
            onClick: () => setShowIntroModal(true),
          }}
        />
      </div>

      <div className="RewardSection__Body">
        <div className="RewardSection__Body__Rewards">
          {rewards?.map((item, i) => (
            <RewardCard key={i} {...item} />
          ))}
        </div>
        <div className="RewardSection__Body__Action">
          <Button
            label={
              <div>
                <img width="16px" src={EditIcon} alt="" /> Edit Reward
              </div>
            }
            customClass="RewardSection__Body__Action--Edit"
            customStyle={{ width: '100%' }}
            onClick={() => history.push('/tutor/editReward', data)}
          />
        </div>
      </div>
      <IntroModal
        showModal={showIntroModal}
        toggleModal={() => setShowIntroModal(false)}
      />
    </div>
  );
}

export default RewardSection;

import React from 'react';
import './style.scss';

function Button({
  label,
  onClick,
  customClass,
  customProps,
  customStyle,
  type,
}) {
  return (
    <button
      className={customClass ? `Button ${customClass}` : `Button`}
      onClick={onClick}
      {...customProps}
      style={
        type
          ? {
              backgroundColor: '#009ae0',
              color: '#ffffff',
              ...customStyle,
            }
          : {
              backgroundColor: '#ffffff',
              color: '#009ae0',
              ...customStyle,
            }
      }
    >
      {label}
    </button>
  );
}

export default Button;

import React from 'react';

import './style.scss';

import Heading from '../../UI/Texts/Heading';

import OverviewCard from '../../UI/OverviewCard';

function Overview({ data, heading }) {
  console.log(data, '!!!!!!!!');
  return (
    <div className="Overview">
      <div className="Overview__Top">
        <Heading heading={heading} />
      </div>
      <div className="Overview__Body">
        {data?.map((item, i) => (
          <OverviewCard
            key={i}
            icon={item?.icon}
            value={item?.count}
            label={item?.text}
          />
        ))}
      </div>
    </div>
  );
}

export default Overview;

import React from 'react';

import './style.scss';

function Loader({ customProps }) {
  return (
    <div className="Loader" {...customProps}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loader;

import React from 'react';
import './style.scss';

import Button from '../Button';

function Footer({ label, onClick, type }) {
  return (
    <div className="Footer">
      <Button
        label={label}
        onClick={onClick}
        type={type}
        customStyle={{ width: '100%' }}
      />
    </div>
  );
}

export default Footer;

import React from 'react';
import { useHistory } from 'react-router';

import './style.scss';

import Heading from '../../UI/Texts/Heading';
import SubHeading from '../../UI/Texts/SubHeading';

import ForwardIcon from '../../../images/Forward.png';

function RewardEntry() {
  const history = useHistory();
  return (
    <div className="RewardEntry" onClick={() => history.push('/tutor/rewards')}>
      <div className="RewardEntry__Left">
        <Heading heading="View Rewards" />
      </div>
      <div className="RewardEntry__Right">
        <SubHeading subHeading="Paytm & Coupons" />
        <img src={ForwardIcon} alt=">" width="8px" />
      </div>
    </div>
  );
}

export default RewardEntry;

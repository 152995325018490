import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import './style.scss';

import { GET } from '../../utilities/constants';
import { apicall } from '../../utilities/apiCall';

import Navbar from '../UI/Navbar';
import Heading from '../UI/Texts/Heading';
import Banner from './HomeAssets/Banner';
import Coupon from './HomeAssets/Coupon';
import Actions from './HomeAssets/Actions';

import Loader from '../UI/Loader';
import Notification from '../UI/Notification';
import Overview from '../tutor/HomeAssets/Overview';
import RefereeList from './HomeAssets/RefereeList';
import SubHeading from '../UI/Texts/SubHeading';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toast from '../UI/Toast';

function StudentHome() {
  const localHistory = useHistory();

  const [data, setData] = useState();
  const [error, catchError] = useState();
  const [offset, updateOffset] = useState(0);
  const [hasMore, updateHasMore] = useState(false);

  const [loading, setLoading] = useState(true);
  const [heading, setHeading] = useState('Loading Offer');
  const [subHeading, setSubHeading] = useState('Loading Details');
  const [referralCode, setReferralCode] = useState('Loading...');
  const [refereeList, setRefereeList] = useState([]);

  const [toastMessage, setToastMessage] = useState();
  const [showToast, setShowToast] = useState(false);

  let limit = 20;

  const getData = () => {
    let action = {
      type: GET,
      url: `v2/course/referral/program/dashboard/student?limit=${limit}&offset=${offset}`,
      storeData: setData,
      catchError: setToastMessage,
    };

    apicall(action);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (toastMessage) {
      setShowToast(true);
    }
  }, [toastMessage]);

  useEffect(() => {
    if (data) {
      console.log(data);
      if (offset === 0) {
        setHeading(data?.heading);
        setSubHeading(data?.subHeading);
        setReferralCode(data?.referralCode);
        setRefereeList(data?.referralStats?.studentList);
        updateHasMore(data?.referralStats?.hasMore);

        if (data?.referralStats?.hasMore) {
          updateOffset(offset + limit);
        }
      } else {
        setRefereeList([...refereeList, ...data?.referralStats?.studentList]);
        updateOffset(offset + limit);
        updateHasMore(data?.referralStats?.hasMore);
      }

      setLoading(false);
    }
  }, [data]);

  const exitApp = () => {
    window?.mobile?.setTestState(`QUIT`);
    window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
  };

  useEffect(() => {
    window.onMobileUpdate = () => {
      exitApp();
    };
  }, []);

  return (
    <div className="StudentHome">
      <Navbar heading={data?.navHeading} onClick={exitApp}>
        <Heading
          heading="FAQs"
          customProps={{
            onClick: () => localHistory.push('/student/faqs'),
            style: { color: '#ffffff' },
          }}
        />
      </Navbar>

      {!loading && data?.isPaused ? (
        <Notification message="Rewards has been paused by tutor." />
      ) : (
        ''
      )}

      <InfiniteScroll
        dataLength={refereeList?.length}
        hasMore={hasMore}
        next={getData}
        height={'calc(100vh - 64px)'}
      >
        <>
          <div className="StudentHome__Top">
            <Banner
              heading={heading}
              subHeading={subHeading}
              isPaused={!loading && data?.isPaused}
            />
            {referralCode ? (
              <Coupon code={referralCode} onCopy={setToastMessage} />
            ) : (
              ''
            )}

            <Actions
              whatsappLink={data?.whatsAppDeepkink}
              shareLink={data?.shareDeeplink}
              buttonLabel={data?.whatsappButtonText}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="StudentHome__Body">
              <Overview
                data={data?.referralStats?.statList}
                heading={data?.referralStats?.title}
              />
              {refereeList?.length ? (
                <RefereeList data={data?.referralStats?.studentList} />
              ) : (
                <SubHeading subHeading="Friends who signed up with your referral link will show up here" />
              )}
            </div>
          )}
        </>
      </InfiniteScroll>
      {showToast ? (
        <Toast
          toggle={() => {
            setShowToast(false);
            setToastMessage();
          }}
          text={toastMessage}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default StudentHome;

import React, { useEffect } from 'react';
import './style.scss';

function Modal({ customClass, customProps, showModal, children, handleClose }) {
  useEffect(() => {
    if (showModal) {
      document?.body?.setAttribute('style', 'overflow-y: hidden;');
    } else {
      document?.body?.setAttribute('style', 'overflow-y: unset;');
    }
  }, [showModal]);
  return (
    <div
      className={
        showModal
          ? 'ModalWrapper ModalWrapper__Show'
          : 'ModalWrapper ModalWrapper__Hide'
      }
    >
      <div
        onClick={handleClose}
        className={
          showModal ? 'Backdrop Backdrop__Show' : 'Backdrop Backdrop__Hide'
        }
      />
      <div
        className={
          showModal
            ? `Modal Modal__Show ${customClass ? customClass : ''}`
            : `Modal Modal__Hide ${customClass ? customClass : ''}`
        }
        {...customProps}
      >
        {children}
      </div>
    </div>
  );
}

export default Modal;

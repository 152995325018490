import React from 'react';
import './style.scss';

function SubHeading({ subHeading, customProps }) {
  return (
    <div className="SubHeading" {...customProps}>
      {subHeading}
    </div>
  );
}

export default SubHeading;

import React, { useEffect } from 'react';

import './style.scss';

import Dropdown from '../../UI/Dropdown';
import Navbar from '../../UI/Navbar';
import { useHistory } from 'react-router';

function FAQ() {
  const history = useHistory();
  const faqs = [
    {
      label: 'What is a successful referral?',
      description:
        'When a student’s friend uses their referral coupon (TnC applied) on the first purchase then it is considered as their successful referral.',
    },
    {
      label: 'How the Paytm cash is paid to students?',
      description:
        'Once a student refers to a new student successfully ( buys an eligible course with referral coupon), the referrer student will receive a reward amount in their Paytm account on the number registered on the app.',
    },
    {
      label: 'How will the Paytm cash be collected from the tutor?',
      description:
        'Paytm cash will be collected from the amount paid by the student ( for successful referral transaction). You will receive the amount after deducting commission, internet handling fee & reward amount.',
    },
    {
      label: 'What will happen if I Pause the referral program?',
      description:
        'if you choose to Pause the referral program then your students will no longer be able to get the benefits of referral rewards and coupons will be invalid. When you resume the program then it will continue as before.',
    },
    {
      label: 'What will happen if I edit the rewards after some days?',
      description:
        'If you edit the rewards then it will be immediately reflected in the student app. The old coupons will have the new discount set by you and the coupon code for each student will remain the same.',
    },
    {
      label:
        'What if the referred student didn’t use the coupon code on the first purchase?',
      description:
        'In this case, the student who referred their friend will not get the referral benefit. Use of referral code by your student’s friend is an essential condition for a successful referral.',
    },
  ];

  useEffect(() => {
    window.onMobileUpdate = () => {
      history.push('/tutor/rewards');
    };
  }, []);

  return (
    <div className="FAQPage">
      <Navbar heading="FAQs" onClick={() => history.push('/tutor/rewards')} />
      <div className="FAQPage__List">
        {faqs?.map((item, i) => (
          <Dropdown
            {...item}
            key={i}
            customClass="FAQPage__List--Item"
            customProps={{
              style: {
                border: '1px solid #e5e5e5',
                borderRadius: '4px',
                padding: '12px',
                marginBottom: '12px',
              },
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default FAQ;

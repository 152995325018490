import React, { useEffect } from 'react';
import './style.scss';

function Toast({ text, toggle }) {
  useEffect(() => {
    setTimeout(() => {
      toggle();
    }, 3000);
  }, []);

  return <div className="Toast">{text}</div>;
}

export default Toast;

import React from 'react';
import './style.scss';

import Heading from '../../UI/Texts/Heading';
import Dropdown from '../../UI/Dropdown';

function FAQSection() {
  const faqs = [
    {
      label: 'What is a successful referral?',
      description:
        'When a student’s friend uses their referral coupon (TnC applied) on the first purchase then it is considered as their successful referral.',
    },
    {
      label: 'How the Paytm cash is paid to students?',
      description:
        'Once a student refers to a new student successfully ( buys an eligible course with referral coupon), the referrer student will receive a reward amount in their Paytm account on the number registered on the app.',
    },
    {
      label: 'How will the Paytm cash be collected from the tutor?',
      description:
        'Paytm cash will be collected from the amount paid by the student ( for successful referral transaction). You will receive the amount after deducting commission, internet handling fee & reward amount.',
    },
  ];

  return (
    <div className="FAQSection">
      <div className="FAQSection__Top">
        <Heading heading="Frequently Asked questions" />
      </div>
      <div className="FAQSection__Body">
        {faqs?.map((item, i) => (
          <Dropdown key={i} {...item} />
        ))}
      </div>
    </div>
  );
}

export default FAQSection;

import { DELETE, GET, POST, PUT } from './constants';
import { apiBaseUrl } from './AppConfig';

export const getQuery = () => {
  let b = window?.location.search
    .slice(1)
    .split('&')
    .map((qStr) => qStr.split('='))
    .reduce((acc, inc) => {
      acc[inc[0]] = inc[1];
      return acc;
    }, {});
  return b;
};

const token = getQuery()['token'] || localStorage.getItem('REFERRALTOKEN');

export const apicall = (action) => {
  switch (action?.type) {
    case GET:
      return fetch(`${apiBaseUrl}/${action?.url}`, {
        method: GET,
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === 'success'
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => {
          action.catchError(err);
        });
    case POST:
      return fetch(`${apiBaseUrl}/${action?.url}`, {
        method: POST,
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
        body: JSON.stringify(action.body),
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === 'success'
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => action.catchError(err));

    case PUT:
      return fetch(`${apiBaseUrl}/${action?.url}`, {
        method: PUT,
        headers: {
          'x-access-token': token,
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
        body: JSON.stringify(action.body),
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === 'success'
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => action.catchError(err));

    case DELETE:
      return;
    default:
      return;
  }
};

import React, { useEffect } from 'react';

import './style.scss';

import Popup from '../../UI/Popup';
import Heading from '../../UI/Texts/Heading';

function Success({ showPopup, toggle }) {
  useEffect(() => {
    if (showPopup) {
      setTimeout(() => {
        toggle();
      }, 3000);
    }
  }, [showPopup]);
  return (
    <Popup showPopup={showPopup} customClass="Success">
      <img
        src="https://storage.googleapis.com/cp-prod-assets-public-as-sth1-gcs-w37fpj/CampaignManager/7c52e8c7490d74b505b8a7e4a1421604"
        alt="image"
      />
      <Heading heading="Great! You have launched Student Referral Program" />
    </Popup>
  );
}

export default Success;

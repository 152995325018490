import React, { useEffect } from 'react';

import './style.scss';

import Dropdown from '../../UI/Dropdown';
import Navbar from '../../UI/Navbar';
import { useHistory } from 'react-router';

function FAQ() {
  const history = useHistory();
  const faqs = [
    {
      label: 'How much I can earn through referral? ',
      description:
        'There is no limitation on your earning. You can refer unlimited friends.',
    },
    {
      label: 'What is a successful referral?',
      description:
        'When your friend uses your referral code on their first purchase (having TnC) then it is considered as your successful referral',
    },
    {
      label: 'For how long is my referral code valid?',
      description:
        'Your referral code is unique with no expiry date and will provide the referral benefits applicable as per the ongoing referral scheme set by your tutor.',
    },
    {
      label: 'How will the Paytm Cash be credited to my account?',
      description:
        'Your reward will be credited to your Paytm linked to the mobile number you are currently logged in. It generally takes 2 days for the Paytm cash to reflect in your Paytm.',
    },
    {
      label: 'My friend isn’t able to use the referral code.',
      description:
        'If your friend isn’t able to your referral code then probably your tutor might have paused the referral program, contact them for clarification.',
    },
    {
      label: 'I don’t have a Paytm account, how can I get Paytm cash?',
      description:
        'If you don’t have a Paytm account at the moment. Then whenever you create a Paytm account with this number then your rewards will be reflected immediately with no extra effort by you.',
    },
    {
      label: 'What if my friend didn’t use my referral code?',
      description:
        'Use of a referral code by your friend is an essential condition for a successful referral. Please make sure your friend is using your coupon code so both of you get the maximum benefits.',
    },
  ];

  useEffect(() => {
    window.onMobileUpdate = () => {
      history.goBack();
    };
  }, []);

  return (
    <div className="FAQPage">
      <Navbar heading="FAQs" />
      <div className="FAQPage__List">
        {faqs?.map((item, i) => (
          <Dropdown
            {...item}
            key={i}
            customClass="FAQPage__List--Item"
            customProps={{
              style: {
                border: '1px solid #e5e5e5',
                borderRadius: '4px',
                padding: '12px',
                marginBottom: '12px',
              },
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default FAQ;

import React from 'react';

import './style.scss';

import Students from '../../../images/Students.png';
import Heading from '../../UI/Texts/Heading';
import SubHeading from '../../UI/Texts/SubHeading';

function NoNewStudents() {
  return (
    <div className="NoNewStudents">
      <img src={Students} alt="NoReferres" width="100px" />
      <Heading heading="You haven’t got any new student" />
      <SubHeading subHeading="Ask your students to refer your app to their friends" />
    </div>
  );
}

export default NoNewStudents;

import React from 'react';
import './style.scss';

function Heading({ heading, customProps }) {
  return (
    <div className="Heading" {...customProps}>
      {heading}
    </div>
  );
}

export default Heading;

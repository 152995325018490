import React from 'react';

import './style.scss';

import Popup from '../../UI/Popup';
import Heading from '../../UI/Texts/Heading';
import SubHeading from '../../UI/Texts/SubHeading';

import PauseIcon from '../../../images/PauseIcon.png';
import { apicall } from '../../../utilities/apiCall';
import { PUT } from '../../../utilities/constants';

function Pause({ showPopup, onClick, handleClose }) {
  const pauseFunction = () => {
    let action = {
      type: PUT,
      url: 'v2/course/referral/program/toggle',
      storeData: (e) => {
        onClick();
      },
      catchError: console.log,
      body: {
        id: localStorage.getItem('ReferralID'),
        status: 'REFERRAL_PAUSE',
      },
    };

    apicall(action);
  };
  return (
    <Popup
      showPopup={showPopup}
      customClass="Prompt Pause"
      handleClose={handleClose}
    >
      <div className="Prompt__Icon">
        <img src={PauseIcon} alt="" />
      </div>
      <div className="Prompt__Body">
        <Heading
          heading="Pause student rewards?"
          customProps={{
            style: {
              fontSize: '18px',
              textAlign: 'center',
              marginBottom: '8px',
            },
          }}
        />
        <SubHeading
          subHeading="You are about to pause student referral rewards."
          customProps={{ style: { textAlign: 'center' } }}
        />
      </div>

      <Heading
        heading="Yes, Pause"
        customProps={{ style: { color: '#009ae0' }, onClick: pauseFunction }}
      />
    </Popup>
  );
}

export default Pause;

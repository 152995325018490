import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style.scss';

import BackArrow from '../../../images/BackArrow.png';

function Navbar({ heading, children, onClick }) {
  const history = useHistory();

  return (
    <div className="Navbar">
      <div className="Navbar__Left">
        <img
          src={BackArrow}
          alt="<-"
          onClick={() => {
            onClick ? onClick() : history.goBack();
          }}
        />
        <div className="Navbar__Left--Heading">{heading}</div>
      </div>
      <div className="Navbar__Right">{children}</div>
    </div>
  );
}

export default Navbar;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import './style.scss';

import Navbar from '../UI/Navbar';
import Heading from '../UI/Texts/Heading';
import RewardEntry from './HomeAssets/RewardEntry';
import Overview from './HomeAssets/Overview';
import Loader from '../UI/Loader';

import { GET } from '../../utilities/constants';
import { apicall } from '../../utilities/apiCall';

import IntroModal from './IntroModal';
import ReferrerList from './HomeAssets/ReferrerList';
import Notification from '../UI/Notification';

function TutorHome() {
  const history = useHistory();

  const [showIntroModal, setShowIntroModal] = useState(false);
  const [data, setData] = useState();
  const [error, catchError] = useState();
  const [offset, updateOffset] = useState(0);
  const [overview, updateOverview] = useState();
  const [referrerList, updateReferrerList] = useState([]);
  const [hasMore, updateHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const limit = 20;

  useEffect(() => {
    updateOffset(0);
    getData();
  }, []);

  const getData = () => {
    let action = {
      type: GET,
      url: `v2/course/referral/program/dashboard?limit=${limit}&offset=${offset}`,
      storeData: setData,
      catchError: catchError,
    };

    apicall(action);
  };

  useEffect(() => {
    if (data?.overview) {
      updateOverview(data?.overview);
    }

    if (data) {
      setLoading(false);
    }

    if (data?.referrerList) {
      offset
        ? updateReferrerList([
            ...referrerList,
            ...data?.referrerList?.studentList,
          ])
        : updateReferrerList(data?.referrerList?.studentList);

      updateOffset(offset + limit);
    }

    setShowIntroModal(data?.showBottomSheet);
    updateHasMore(data?.referrerList?.hasMore);
  }, [data]);

  const exitApp = () => {
    window?.mobile?.setTestState(`QUIT`);
    window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
  };

  useEffect(() => {
    window.onMobileUpdate = () => {
      exitApp();
    };
  }, []);

  return (
    <div className="TutorHome">
      <Navbar heading="Student Referral" onClick={exitApp}>
        <Heading
          heading="FAQs"
          customProps={{
            onClick: () => history.push('/tutor/faqs'),
            style: { color: '#ffffff' },
          }}
        />
      </Navbar>

      {data?.isPaused && !loading ? (
        <Notification message="Student rewards have been paused" />
      ) : (
        ''
      )}

      <div
        className="TutorHome__Top"
        style={{ marginTop: data?.isPaused && !loading ? '48px' : '0' }}
      >
        <RewardEntry />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="TutorHome__Body">
          <InfiniteScroll
            dataLength={referrerList?.length}
            next={getData}
            hasMore={hasMore}
            height={'40vh'}
          >
            <Overview data={overview?.statList} heading={overview?.title} />
            <ReferrerList data={referrerList} hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      )}

      <IntroModal
        showModal={showIntroModal}
        toggleModal={() => history.push('/tutor/rewards')}
      />
    </div>
  );
}

export default TutorHome;

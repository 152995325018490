import React, { useEffect, useState } from 'react';

import './style.scss';

import ReferrerCard from '../../UI/ReferrerCard';
import Heading from '../../UI/Texts/Heading';

import Forward from '../../../images/Forward.png';
import RefereeModal from '../RefereeModal';
import { GET } from '../../../utilities/constants';
import { apicall } from '../../../utilities/apiCall';
import NoNewStudents from './NoNewStudents';

function ReferrerList({ data }) {
  const [loading, setLoading] = useState(true);

  const [showRefereeModal, setShowRefereeModal] = useState(false);
  const [refereeList, updateRefereeList] = useState();
  const [activeReferrer, setActiveReferrer] = useState();
  const [error, catchError] = useState();

  const [offset, updateOffset] = useState(0);
  const [hasMore, updateHasMore] = useState(true);
  const [totalCount, updateTotalCount] = useState();
  let limit = 10;

  const getRefereeList = () => {
    let action = {
      type: GET,
      url: `v2/course/referral/program/dashboard/refereeList/${activeReferrer?.userId}?limit=${limit}&offset=${offset}`,
      storeData: (e) => {
        setRefereeList(e);
        setLoading(false);
      },
      catchError: catchError,
    };

    apicall(action);
  };

  const setRefereeList = (data) => {
    updateRefereeList(offset ? [...refereeList, ...data] : data);
    updateHasMore(activeReferrer?.count > offset + limit);
    updateOffset(offset + limit);
  };

  useEffect(() => {
    if (activeReferrer) {
      setShowRefereeModal(true);
      updateTotalCount(activeReferrer?.count);
    } else {
      setShowRefereeModal(false);
    }
  }, [activeReferrer]);

  return (
    <div className="ReferrerList">
      <div className="ReferrerList__Top">
        <Heading heading="Your students who referred" />
      </div>

      <div className="ReferrerList__Body">
        {data?.length ? (
          data?.map((item, i) => (
            <ReferrerCard
              name={item?.name}
              image={item?.iconUrl}
              subtext={`Referred: ${item?.count}`}
              key={item?.userId}
              onClick={() => {
                setLoading(true);
                setActiveReferrer({ ...[], ...item });
              }}
            >
              <img src={Forward} alt="" width="8px" />
            </ReferrerCard>
          ))
        ) : (
          <NoNewStudents />
        )}
      </div>

      <RefereeModal
        showModal={showRefereeModal}
        refereeList={refereeList}
        referrer={activeReferrer}
        toggle={() => {
          setShowRefereeModal(false);
          setRefereeList([]);
          updateOffset(0);
        }}
        next={getRefereeList}
        hasMore={hasMore}
        loading={loading}
      />
    </div>
  );
}

export default ReferrerList;

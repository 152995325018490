import React from 'react';

import './style.scss';

import BannerImage from '../../../images/Banner.png';

import Heading from '../../UI/Texts/Heading';
import SubHeading from '../../UI/Texts/SubHeading';

function Banner({ heading, subHeading, isPaused }) {
  return (
    <div className="Banner" style={{ marginTop: isPaused ? '48px' : '0' }}>
      <div className="Banner__Top">
        <img src={BannerImage} alt="" />
        <div className="Banner__Top__Description">
          <Heading
            heading={heading}
            customProps={{ style: { fontSize: '20px' } }}
          />
          <SubHeading subHeading={subHeading} />
        </div>
      </div>
    </div>
  );
}

export default Banner;

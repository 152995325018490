import React from 'react';

import './style.scss';

import WhatsAppIcon from '../../../images/WhatsAppIcon.png';
import ShareIcon from '../../../images/ShareIcon.png';
import Button from '../../UI/Button';
import { directDeepLink } from '../../../utilities/deepLinks';
import { SOURCE_WEBVIEW } from '../../../utilities/constants';

function Actions({ whatsappLink, shareLink, buttonLabel }) {
  const shareDeeplink = {
    screen: 'UTIL_SHARE',
    paramOne: 'U09NRSBURVhU',
    paramTwo: '',
  };

  const whatsappDeeplink = {
    screen: 'UTIL_WHATSAPP',
    paramOne: `Hey! 
    Check out this app. They have many amazing courses available in their store. Use my code  and get % on your first purchase.
    
    You can learn from anywhere anytime. Learn from the best instructors. Take live classes. Quizzes. download videos.PDFs and much more. 
    Download App from below link. `,
    paramTwo: '',
  };
  return (
    <div className="CouponActions">
      <Button
        customClass="CouponActions--Whatsapp"
        label={
          <div>
            <img src={WhatsAppIcon} alt="WhatsApp" width="20px" />
            <span>{buttonLabel}</span>
          </div>
        }
        type={1}
        customStyle={{ backgroundColor: '#08BD80', border: 'none' }}
        onClick={directDeepLink(whatsappLink, SOURCE_WEBVIEW)}
      />
      <Button
        customClass="CouponActions--Share"
        label={<img src={ShareIcon} alt="Share" width="20px" />}
        type={0}
        customStyle={{ border: '1px solid #e5e5e5', padding: '12px' }}
        onClick={directDeepLink(shareLink, SOURCE_WEBVIEW)}
      />
    </div>
  );
}

export default Actions;

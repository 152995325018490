import React from 'react';

import './style.scss';

import CopyIcon from '../../../images/CopyIcon.png';
import { directDeepLink } from '../../../utilities/deepLinks';
import { SOURCE_WEBVIEW } from '../../../utilities/constants';

function Coupon({ code, onCopy }) {
  const copyDeeplink = {
    screen: 'UTIL_COPY',
    paramOne: code,
    paramTwo: '',
  };
  const copyToClipboard = () => {
    window?.navigator?.clipboard?.writeText(code);
    // onCopy('Copied Successfully');
    directDeepLink(copyDeeplink, SOURCE_WEBVIEW)();
  };
  return (
    <div className="Coupon" onClick={copyToClipboard}>
      <div className="Coupon--Code">{code}</div>

      <div className="Coupon--Copy">
        <img src={CopyIcon} alt="" width="16px" />
      </div>
    </div>
  );
}

export default Coupon;

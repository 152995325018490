import React from 'react';

import './style.scss';

import SubHeading from '../Texts/SubHeading';

function InputField({ label, type, value, onChange, customProps }) {
  return (
    <div className="Input">
      <label>
        <SubHeading subHeading={label} />
        <input
          className="Input__Field"
          type={type ? type : 'text'}
          value={value}
          onChange={onChange}
          {...customProps}
        />
      </label>
    </div>
  );
}

export default InputField;

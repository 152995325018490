import React from 'react';

import './style.scss';

import Heading from '../Texts/Heading';
import SubHeading from '../Texts/SubHeading';
import ProfilePlaceholder from '../ProfilePlaceholder';

function ReferrerCard({
  name,
  image,
  subtext,
  customProps,
  onClick,
  children,
  textColor,
}) {
  return (
    <div className="ReferrerCard" {...customProps} onClick={onClick}>
      <div className="ReferrerCard__Referrer">
        <div className="ReferrerCard__Referrer--Profile">
          {image ? (
            <img src={image} alt="" />
          ) : (
            <ProfilePlaceholder name={name} />
          )}
        </div>
        <div className="ReferrerCard__Referrer--Details">
          <Heading heading={name} />
          <SubHeading
            subHeading={subtext}
            customProps={{
              style: {
                fontSize: '12px',
                lineHeight: '14px',
                color: textColor ? textColor : '#666666',
              },
            }}
          />
        </div>
      </div>
      <div className="ReferrerCard__Right">{children}</div>
    </div>
  );
}

export default ReferrerCard;

import React from 'react';
import './style.scss';

function OverviewCard({ customProps, value, label, icon }) {
  return (
    <div className="OverviewCard" {...customProps}>
      <img src={icon} alt={label} />
      <div className="OverviewCard__Text">
        <div className="OverviewCard__Text--Value">{value}</div>
        <div className="OverviewCard__Text--Label">{label}</div>
      </div>
    </div>
  );
}

export default OverviewCard;

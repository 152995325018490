import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './style.scss';

import Navbar from '../../UI/Navbar';
import Heading from '../../UI/Texts/Heading';
import Footer from '../../UI/Footer';
import Toast from '../../UI/Toast';

import RewardSection from '../Sections/RewardSection';
import FAQSection from '../Sections/FAQSection';
import Pause from '../Propmts/Pause';
import Play from '../Propmts/Play';

import { apicall } from '../../../utilities/apiCall';
import { GET, PUT } from '../../../utilities/constants';
import Success from '../Propmts/Success';
import Loader from '../../UI/Loader';

function Rewards({ history }) {
  const localHistory = useHistory();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, catchError] = useState();
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [showToast, setShowToast] = useState(false);
  const [isPause, updateIsPaused] = useState(false);

  const getData = () => {
    let action = {
      type: GET,
      url: `v2/course/referral/orgConfig`,
      storeData: setData,
      catchError: catchError,
    };

    apicall(action);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (history?.location?.state?.toastMessage) {
      setToastMessage(history?.location?.state?.toastMessage);
    }
  }, [history?.location?.state?.toastMessage]);

  useEffect(() => {
    if (toastMessage) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [toastMessage]);

  useEffect(() => {
    if (data?.id !== undefined) {
      localStorage.setItem('ReferralID', data?.id);
    }
    if (data) {
      setLoading(false);
    }
    updateIsPaused(data?.status === 'REFERRAL_PAUSE');
  }, [data]);

  const launchRewards = () => {
    let action = {
      type: PUT,
      url: 'v2/course/referral/program/toggle',
      storeData: (e) => {
        setShowSuccessPopup(true);
      },
      catchError: setToastMessage,
      body: {
        id: parseInt(localStorage.getItem('ReferralID')),
        status: 'REFERRAL_LAUNCH',
      },
    };

    apicall(action);
  };

  const footerButton = (type) => {
    switch (type) {
      case 'REFERRAL_LAUNCH':
        return {
          label: 'Launch Student Referral',
          onClick: () => launchRewards(),
        };
      case 'REFERRAL_PAUSE':
        return {
          label: 'Pause Student Rewards',
          onClick: () => setShowPauseModal(true),
        };
      case 'REFERRAL_RESUME':
        return {
          label: 'Resume Student Rewards',
          onClick: () => setShowResumeModal(true),
        };
    }
  };

  useEffect(() => {
    window.onMobileUpdate = () => {
      localHistory.push('/');
    };
  }, []);

  return (
    <div className="Rewards">
      <Navbar heading="Rewards" onClick={() => localHistory.push('/')}>
        <Heading
          heading="FAQs"
          customProps={{
            onClick: () => localHistory.push('/tutor/faqs'),
            style: { color: '#ffffff' },
          }}
        />
      </Navbar>
      {loading ? (
        <Loader />
      ) : (
        <div className="Rewards__Body">
          <RewardSection data={data} />

          {data?.showFAQ ? <FAQSection /> : ''}
        </div>
      )}

      {data?.status ? <Footer {...footerButton(data?.status)} type={1} /> : ''}

      <Pause
        showPopup={showPauseModal}
        onClick={() => {
          setShowPauseModal(false);
          getData();
          setToastMessage('Student rewards have been paused');
        }}
        handleClose={() => setShowPauseModal(false)}
      />
      <Play
        showPopup={showResumeModal}
        onClick={() => {
          setShowResumeModal(false);
          getData();
          setToastMessage('Student rewards resumed');
        }}
        handleClose={() => setShowResumeModal(false)}
      />
      <Success
        showPopup={showSuccessPopup}
        toggle={() => localHistory.push('/')}
      />

      {showToast ? (
        <Toast
          text={toastMessage}
          toggle={() => {
            setShowToast(false);
            setToastMessage();
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Rewards;

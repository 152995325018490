import React, { useState } from 'react';
import './style.scss';

import Heading from '../Texts/Heading';
import SubHeading from '../Texts/SubHeading';

import ToggleIcon from '../../../images/DropdownToggle.png';

function Dropdown({ customClass, customProps, label, description }) {
  const [showDetails, updateShowDetails] = useState(false);

  return (
    <div
      className={customClass ? `Dropdown ${customClass}` : 'Dropdown'}
      {...customProps}
      onClick={() => updateShowDetails(!showDetails)}
    >
      <div className="Dropdown__Main">
        <Heading
          heading={label}
          customProps={{
            style: { fontSize: '14px', color: '#666666', fontWeight: '600' },
          }}
        />
        <div
          className={
            showDetails
              ? 'Dropdown__Main--Toggle Dropdown__Main--Toggle--Show'
              : 'Dropdown__Main--Toggle Dropdown__Main--Toggle--Hide'
          }
        >
          <img
            className={
              showDetails
                ? 'Dropdown__Main--Toggle--Verticle Dropdown__Main--Toggle--Verticle--Show'
                : 'Dropdown__Main--Toggle--Verticle Dropdown__Main--Toggle--Verticle--Hide'
            }
            src={ToggleIcon}
            width="16px"
            alt="-"
          />
          <img
            className="Dropdown__Main--Toggle--Horizontal"
            src={ToggleIcon}
            alt="-"
            width="16px"
          />
        </div>
      </div>
      <div
        className={
          showDetails
            ? 'Dropdown__Extended Dropdown__Extended--Show'
            : 'Dropdown__Extended Dropdown__Extended--Hide'
        }
      >
        <SubHeading subHeading={description} />
      </div>
    </div>
  );
}

export default Dropdown;

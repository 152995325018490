import { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import './App.scss';

import StudentHome from './components/student';
import TutorHome from './components/tutor';
import FAQ from './components/tutor/FAQ';
import Edit from './components/tutor/Edit';
import StudentFAQ from './components/student/FAQ';

import { getQuery } from './utilities/apiCall';

import Rewards from './components/tutor/Rewards';

function App() {
  let token = getQuery()['token'] || localStorage.getItem('REFERRALTOKEN');

  useEffect(() => {
    if (token) {
      localStorage.setItem('REFERRALTOKEN', token);
    }
  }, [token]);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={TutorHome} />
          <Route exact path="/tutor/faqs" component={FAQ} />
          <Route exact path="/tutor/rewards" component={Rewards} />
          <Route exact path="/tutor/editReward" component={Edit} />
          <Route exact path="/student" component={StudentHome} />
          <Route exact path="/student/faqs" component={StudentFAQ} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

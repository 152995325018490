import React from 'react';
import { useHistory } from 'react-router';

import './style.scss';

import Modal from '../../UI/Modal';
import Button from '../../UI/Button';
import Heading from '../../UI/Texts/Heading';

import PeopleIcon from '../../../images/PeopleIcon.png';

function IntroModal({ showModal, toggleModal }) {
  const history = useHistory();
  const rules = [
    {
      bullet: '01',
      rule: 'Your student refers app to new friends',
    },
    { bullet: '02', rule: 'New student signs up and gets a coupon.' },
    {
      bullet: '03',
      rule: 'Your student gets Paytm cash after new student buys your course.',
    },
  ];
  return (
    <Modal
      customClass="IntroModal"
      showModal={showModal}
      handleClose={toggleModal}
    >
      <div className="IntroModal__Top">
        <div className="IntroModal__Top--Icon">
          <img src={PeopleIcon} alt="" />
        </div>
        <div className="IntroModal__Top--Heading">
          Get more students <br />
          through your students
        </div>
      </div>
      <div className="IntroModal__Body">
        <div className="IntroModal__Body__Rules">
          {rules?.map((item, i) => (
            <div key={i} className="IntroModal__Body__Rules--Rule">
              <span className="IntroModal__Body__Rules--Rule--Bullet">
                {item?.bullet}
              </span>
              <span className="IntroModal__Body__Rules--Rule--Text">
                {item?.rule}
              </span>
            </div>
          ))}
        </div>
        <div className="IntroModal__Body__Actions">
          <Button
            customClass="IntroModal__Body__Actions--GotIt"
            label="Set Rewards Now"
            type={1}
            onClick={toggleModal}
          />

          <Heading
            heading="See FAQs"
            customProps={{
              style: { color: '#009ae0' },
              onClick: () => history.push('/tutor/faqs'),
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default IntroModal;

import React from 'react';
import { SOURCE_WEBVIEW } from '../../../utilities/constants';
import { directDeepLink } from '../../../utilities/deepLinks';
import Button from '../../UI/Button';
import ReferrerCard from '../../UI/ReferrerCard';

function RefereeList({ data }) {
  return (
    <div className="RefereeList">
      {data?.map((item, i) => (
        <ReferrerCard {...item} subtext={item?.typeText}>
          <Button
            label={item?.boxName}
            customStyle={{ padding: '4px 12px', fontSize: '12px' }}
            type={1}
            customProps={{ disabled: !item?.remindDeeplink }}
            onClick={() => {
              console.log('HELLO');
              directDeepLink(item?.remindDeeplink, SOURCE_WEBVIEW)();
            }}
          />
        </ReferrerCard>
      ))}
    </div>
  );
}

export default RefereeList;
